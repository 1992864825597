/*
Template Name: Franky
Version: 1.0.0
Author: Jthemes
Author URI: http://themeforest.net/user/Jthemes
Description: Portfolio/CV/Resume HTML Template

[TABLE OF CONTENTS]

01. Base
02. Typography
03. Preloader
04. Header
05. Sections
06. Parallax Shapes
07. Skills
08. Service
09. Portfolio
10. Facts
11. Prices
12. Testimonials
13. Blog
14. Clients
15. Contact
16. Helper
17. Dark Mode
18. Buttons
19. Bootstrap Components
20. Theme elements
21. Slick
22. Responsive

*/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic');
@import "mixins";
@import "base";
@import "header";
@import "main";
@import "dark";
@import "buttons";
@import "elements";
@import "slick";
@import "responsive";